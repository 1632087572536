<img
  *ngIf="image"
  [src]="image"
  alt=""
/>
<kor-grid
  class="section"
  columns="3"
>
  <div
    class="text"
    grid-cols-s="3"
  >
    <div class="title">
      <kor-icon
        *ngIf="icon"
        [icon]="icon"
        size="l"
      ></kor-icon>
      <kor-text
        *ngIf="heading"
        size="header-1"
      >{{ heading }}</kor-text>
    </div>
    <kor-text
      *ngIf="subheading"
      size="header-1"
      color="var(--text-2)"
    >{{ subheading }}</kor-text>
    <kor-text
      *ngIf="body"
      size="body-1"
    >{{ body }}</kor-text>
    <ng-content></ng-content>
  </div>
</kor-grid>