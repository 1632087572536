<div class="section">
  <kor-grid [columns]="items.length">
    <div
      *ngFor="let item of items"
      [attr.grid-cols-s]="items.length"
    >
      <kor-image
        *ngIf="item.image"
        height="320"
        fit="contain"
        [src]="item.image"
      ></kor-image>
      <div class="title">
        <kor-icon
          *ngIf="item.icon"
          [icon]="item.icon"
        ></kor-icon>
        <kor-text
          *ngIf="item.heading"
          size="header-1"
        >{{ item.heading }}</kor-text>
      </div>
      <kor-text
        *ngIf="item.body"
        color="var(--text-2)"
      > {{ item.body }} </kor-text>
    </div>
  </kor-grid>
</div>