<app-stage
  theme="dark"
  image="./assets/imgs/00.png"
  icon="url(https://jooni.app/assets/imgs/favicon_64.png)"
  heading="Jooni –"
  subheading="Track and share your bonsai journey"
  class="left bottom stretch"
  body="Available as:"
  style="--bg-color: 0, 0, 0;"
>
  <div class="buttons">
    <a
      href="https://jooni.app/"
      target="blank"
    >
      <kor-button label="Web App"></kor-button>
    </a>
    <a
      href="https://play.google.com/store/apps/details?id=com.jooni.app"
      target="blank"
    >
      <kor-button
        label="Android App"
        color="tertiary"
      ></kor-button>
    </a>
    <a
      href="https://apps.apple.com/ph/app/jooni-bonsai/id1537602737"
      target="blank"
    >
      <kor-button
        label="iOS App"
        color="tertiary"
      ></kor-button>
    </a>
  </div>
</app-stage>
<app-grid [items]="featuresGridItems"></app-grid>
<app-stage
  image="./assets/imgs/01.png"
  icon="event"
  heading="Track your plant's care"
  body="Log details and pictures of care events in your plant's journal."
  class="right"
></app-stage>
<app-stage
  image="./assets/imgs/02.png"
  icon="nature"
  heading="Browse the gallery"
  body="Discover plants from other users, learn from their past experiences and get inspired."
  class="left"
  style="--bg-color: var(--base-1);"
></app-stage>
<app-stage
  image="./assets/imgs/03.png"
  icon="assignment"
  heading="Care instructions"
  body="Browse bonsai care guidelines for different genus and species."
  class="right"
></app-stage>
<app-grid
  theme="dark"
  [items]="socialsGridItems"
></app-grid>