import { Component } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  featuresGridItems = [
    {
      image: 'assets/imgs/free.png',
      heading: 'Free everywhere',
      body: 'Whether using the mobile apps or the desktop webpage, Jooni remains free to use and in sync across all devices.',
    },
    {
      image: 'assets/imgs/community.png',
      heading: 'Join the community',
      body: 'Jooni is a community to learn and share knowledge and experiences across all levels, from beginner to expert.',
    },
    {
      image: 'assets/imgs/reminders.png',
      heading: 'Keep track of care events',
      body: 'Routine events such as repotting and fertilizing trigger reminders to ensure you never miss a care event.',
    },
  ];
  socialsGridItems = [
    {
      icon: 'url(https://jooni.app/assets/imgs/icons/social_Instagram.png)',
      heading: '@jooni.bonsai'
    },
    {
      icon: 'url(https://upload.wikimedia.org/wikipedia/de/thumb/9/9f/Twitter_bird_logo_2012.svg/1259px-Twitter_bird_logo_2012.svg.png)',
      heading: '@jooni_bonsai'
    },
    {
      icon: 'url(https://jooni.app/assets/imgs/icons/social_Facebook.png)',
      heading: 'jooni.bonsai'
    },
    {
      icon: 'email',
      heading: 'jooni.app@gmail.com'
    },
  ];
}
